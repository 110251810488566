import React, {useContext, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import useFetch from "../../utils/useFetch";
import {PartToFig} from "../../models/PartToFig";
import Loader from "../../components/loader/loader";
import "./ComponentParts.scss";
import MachineContext from "../../utils/MachineContext";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import {TransformEvent} from "../../models/TransformEvent";
import * as Checkbox from '@radix-ui/react-checkbox';
import {CheckIcon} from '@radix-ui/react-icons';
import {useShoppingCart} from "../../utils/ListContext";
import ClearCartButton from "../../components/clearcartbutton/ClearCartButton";
import * as ScrollArea from "@radix-ui/react-scroll-area";

export const ComponentParts: React.FC = () => {

    let currentImageIndex = 0;
    const {machine} = useContext(MachineContext);

    const params = useParams();
    const figId = params.figId as string;
    const componentId = params.componentId as string;
    const url = process.env.REACT_APP_API_URL + `/api/v1/fig-to-feature/${figId}/parts`;

    const matchedFeature = machine?.features?.find(feature => feature.id === Number.parseInt(componentId as string));
    const matchedFig = matchedFeature?.figToFeatures?.find(feature => feature.id === Number.parseInt(figId as string));

    const [transformState, setTransformState] = useState({
        scale: 1,
    });

    const [selectedPartId, setSelectedPartId] = useState<number | null>(null);
    const handleRectangleClick = (partId: number) => {
        setSelectedPartId(partId);
    };

    function handleScaleChange(event: TransformEvent) {
        setTransformState({scale: event.instance.transformState.scale});
    }

    const maxScale = 2;
    const wheelStep = 0.1;
    const imageRef = useRef<HTMLImageElement>(null);
    const [imageSize, setImageSize] = useState({width: 0, height: 0, renderedWidth: 0, renderedHeight: 0});

    useEffect(() => {
        if (imageRef.current) {
            const {naturalWidth, naturalHeight, clientWidth, clientHeight} = imageRef.current;
            setImageSize({
                width: naturalWidth,
                height: naturalHeight,
                renderedWidth: clientWidth,
                renderedHeight: clientHeight
            });
        }
    }, [imageRef.current]);

    const renderedScaleX = imageSize.renderedWidth / imageSize.width;
    const renderedScaleY = imageSize.renderedHeight / imageSize.height;

    const {
        data: parts,
        loading: loadingParts,
        error
    } = useFetch<PartToFig[]>(url);

    const {addItem, cartItems, removeItem} = useShoppingCart();

    const handleCheckboxChange = (part: PartToFig, checked: unknown) => {
        const isChecked = Boolean(checked);
        if (isChecked) {
            addItem(part);
        } else {
            removeItem(part.id);
        }
    };

    if (loadingParts) {
        return <Loader/>;
    }

    if (!matchedFig || error) {
        return <div>Feature not found</div>;
    }

    return (
        <div id={"component-parts-wrapper"}>
            <h1 className={"component-h1"}>{matchedFig.name}</h1>
            <div className="basic-machine-information">
                <span>Model: {machine?.modelName}</span>
                <span>Book Code: {machine?.bookCode}</span>
                <span>Komponent: {matchedFeature?.featureName}</span>
            </div>
            <div className="component-parts">
                <div className="component-parts-first-row-wrapper wobis-row wobis-col2">
                    <div className="wobis-imgs-container">
                        <div className="wobis-component-imgs-carousel">
                            <div className="wobis-component-imgs-carousel-wrapper">
                                {matchedFig?.illustrations?.map((illustration, index) => (
                                    <div key={index} className="wobis-component-imgs-carousel-item">
                                        <img src={illustration.url} alt={matchedFig?.name} className={"carousel-img"}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="component-imgs-wrapper machine-overview-img">
                            <TransformWrapper
                                initialScale={1}
                                maxScale={maxScale}
                                minScale={0.7}
                                wheel={{step: wheelStep}}
                                centerOnInit={true}
                                onTransformed={(e) => handleScaleChange(e)}
                            >
                                {({zoomIn, zoomOut, resetTransform, centerView, ...rest}) => (
                                    <React.Fragment>
                                        <div className="tools">
                                            <button onClick={() => zoomIn()} className={"zoom-in"}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M10.2438 17.7377C14.3825 17.7377 17.7377 14.3825 17.7377 10.2438C17.7377 6.10512 14.3825 2.75 10.2438 2.75C6.10512 2.75 2.75 6.10512 2.75 10.2438C2.75 14.3825 6.10512 17.7377 10.2438 17.7377Z"
                                                        stroke="currentColor" strokeWidth="1" strokeLinecap="round"
                                                        strokeLinejoin="round"/>
                                                    <path d="M15.5621 15.5621L21.25 21.25" stroke="currentColor"
                                                          strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M10.2439 6.24466V14.2431" stroke="currentColor"
                                                          strokeWidth="1"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.243 10.2439H6.24463" stroke="currentColor"
                                                          strokeWidth="1"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                <span>Przybliż</span>
                                            </button>
                                            <button onClick={() => zoomOut()} className={"zoom-out"}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M10.2438 17.7377C14.3826 17.7377 17.7377 14.3825 17.7377 10.2438C17.7377 6.10511 14.3826 2.75 10.2438 2.75C6.10513 2.75 2.75002 6.10511 2.75002 10.2438C2.75002 14.3825 6.10513 17.7377 10.2438 17.7377Z"
                                                        stroke="currentColor" strokeWidth="1" strokeLinecap="round"
                                                        strokeLinejoin="round"/>
                                                    <path d="M15.5621 15.5621L21.25 21.25" stroke="currentColor"
                                                          strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.2429 10.2439H6.24452" stroke="currentColor"
                                                          strokeWidth="1"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                <span>Oddal</span>
                                            </button>
                                            <button onClick={() => centerView()} className={"zoom-center"}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 4.5V19.5" stroke="currentColor" strokeWidth="1"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M19.5 12H4.5" stroke="currentColor" strokeWidth="1"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                <span>Wyśrodkuj</span>
                                            </button>
                                            <button onClick={() => resetTransform()} className={"zoom-reset"}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M6.39462 7.70537C7.12924 6.96718 8.00279 6.38183 8.96495 5.98308C9.927 5.58435 10.9586 5.38013 12 5.38222C14.1028 5.38222 16.1195 6.21757 17.6064 7.70446C19.0933 9.19136 19.9286 11.208 19.9286 13.3107C19.9286 15.4146 19.0936 17.4323 17.607 18.9209C16.1204 20.4095 14.1038 21.2472 12 21.25C9.89625 21.2472 7.87961 20.4095 6.39303 18.9209C4.90645 17.4323 4.07141 15.4146 4.07141 13.3107"
                                                        stroke="currentColor" strokeWidth="1" strokeMiterlimit="10"
                                                        strokeLinecap="round"/>
                                                    <path
                                                        d="M7.11928 2.75L6.17085 6.60772C6.08702 6.94965 6.14202 7.31085 6.32371 7.61238C6.5055 7.9139 6.79909 8.13125 7.14066 8.21689L11.009 9.16532"
                                                        stroke="currentColor" strokeWidth="1" strokeLinecap="round"
                                                        strokeLinejoin="round"/>
                                                </svg>
                                                Resetuj
                                            </button>
                                        </div>
                                        <span
                                            id={"component-img-count"}>{currentImageIndex + 1}/{matchedFig?.illustrations?.length}</span>
                                        <TransformComponent>
                                            <img ref={imageRef} src={matchedFig?.illustrations?.at(0)?.url}
                                                 alt={matchedFig?.name}/>
                                            <div style={{
                                                position: 'absolute',
                                                left: 0,
                                                top: 0,
                                                width: '100%',
                                                height: '100%',
                                                // transform: `translate(${transformState.positionX}px, ${transformState.positionY}px) scale(${transformState.scale})`
                                            }}>
                                                {parts?.map(part =>
                                                    part.positions?.map((pos, index) => (
                                                        <a href={`#${part.id}`}
                                                           key={`${part.id}-${index}`}
                                                           style={{
                                                               position: 'absolute',
                                                               left: `${pos.startX * renderedScaleX}px`,
                                                               top: `${pos.startY * renderedScaleY}px`,
                                                               width: `${(pos.endX - pos.startX) * renderedScaleX}px`,
                                                               height: `${(pos.endY - pos.startY) * renderedScaleY}px`,
                                                               border: '1px solid red',
                                                               boxSizing: 'border-box',
                                                               transform: 'scale(1.4)',
                                                               cursor: 'pointer'
                                                           }}
                                                           about={part.id.toString()}
                                                           onClick={() => handleRectangleClick(part.id)}
                                                        />
                                                    ))
                                                )}
                                            </div>
                                        </TransformComponent>
                                    </React.Fragment>
                                )}
                            </TransformWrapper>
                        </div>
                    </div>
                    <div className="part-list-selected">
                        <ScrollArea.Root className={"ScrollAreaRoot"}>
                            <ScrollArea.Viewport className="ScrollAreaViewport">
                                <table className={"part-list-table-selected part-list-table"}>
                                    <thead>
                                    <tr>
                                        <th>Kod</th>
                                        <th>Nazwa</th>
                                        <th>Ilość</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {cartItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.partNumber}</td>
                                            <td>{item.name}</td>
                                            <td>{item.quantity}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </ScrollArea.Viewport>
                            <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="vertical">
                                <ScrollArea.Thumb className="ScrollAreaThumb"/>
                            </ScrollArea.Scrollbar>
                            <ScrollArea.Corner className="ScrollAreaCorner"/>
                        </ScrollArea.Root>
                        <div id={"selected-parts-footer"}>
                            <ClearCartButton/>
                        </div>
                    </div>
                </div>
                <div className="part-list-wrapper">
                    <ScrollArea.Root className={"ScrollAreaRoot"}>
                        <ScrollArea.Viewport className="ScrollAreaViewport">
                            <table className={"part-list-table"}>
                                <thead>
                                <tr>
                                    <th>Numer</th>
                                    <th>Kod Części</th>
                                    <th>Nazwa Części</th>
                                    <th>Ilość</th>
                                    <th>Numer seryjny</th>
                                    <th>Waga</th>
                                    <th>Jednostka</th>
                                    <th>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.53657 21.25H7.54758" stroke="currentColor" strokeWidth="2.5"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M17.9381 21.25H17.9491" stroke="currentColor" strokeWidth="2.5"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M4.62838 6.52571H9.60334C12.3104 6.52571 15.0175 6.52571 17.7246 6.52571C18.2498 6.50271 18.7743 6.58341 19.2682 6.76318C19.5116 6.85995 19.73 7.01065 19.9069 7.20397C20.0837 7.39727 20.2145 7.62817 20.2893 7.87927C20.3493 8.45024 20.2761 9.02733 20.0756 9.56528C19.9449 10.1351 19.8263 10.7526 19.7075 11.275C19.4462 12.4624 19.1851 13.6497 18.9714 14.8371C18.9205 15.4101 18.7016 15.9553 18.3421 16.4043C18.1157 16.6114 17.8494 16.7699 17.5594 16.8699C17.2693 16.9699 16.962 17.0095 16.6561 16.986C15.6349 16.986 14.602 16.986 13.569 16.986H9.80509C9.25234 17.0394 8.69568 17.0394 8.14288 16.986C7.8537 16.9546 7.5781 16.8469 7.34439 16.6736C7.11069 16.5004 6.9273 16.2681 6.81307 16.0006C6.61691 15.3381 6.46226 14.6639 6.35001 13.9822C6.25503 13.4478 6.13629 12.9136 6.01756 12.3793C5.60199 10.3964 5.12706 8.43731 4.62838 6.52571ZM4.62838 6.52571C4.31967 5.26714 3.9991 4.00857 3.69039 2.75"
                                                stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path d="M19.5531 11.9993H5.93447" stroke="currentColor" strokeWidth="1.5"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {parts?.map((part, index) => (
                                    <tr id={part.id.toString()} key={index}
                                        className={selectedPartId == part.id ? "highlighted-row" : ""}>
                                        <td>{part.referenceNumber}</td>
                                        <td>{part.wobisPartNumber}</td>
                                        <td>{part.name}</td>
                                        <td>{part.quantity}</td>
                                        <td>{part.serialNumber}</td>
                                        <td>{part.weight}</td>
                                        <td>{part.weightUnit}</td>
                                        <td>
                                            <Checkbox.Root className="CheckboxRoot" defaultChecked={false}
                                                           checked={cartItems.some(item => item.id === part.id)}
                                                           onCheckedChange={(checked) => handleCheckboxChange(part, checked)}>
                                                <Checkbox.Indicator className="CheckboxIndicator">
                                                    <CheckIcon/>
                                                </Checkbox.Indicator>
                                            </Checkbox.Root>
                                        </td>
                                    </tr>
                                ))}

                                </tbody>
                            </table>
                        </ScrollArea.Viewport>
                        <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="vertical">
                            <ScrollArea.Thumb className="ScrollAreaThumb"/>
                        </ScrollArea.Scrollbar>
                        <ScrollArea.Corner className="ScrollAreaCorner"/>
                    </ScrollArea.Root>
                </div>
            </div>
        </div>
    );
}